import './App.css';
import { Container, Row, Col } from 'react-bootstrap';
import background from './images/background5.mp4'
import backgroundPoster from './images/background5.png'

function App() {
  return (
    <div className="App">
      <video
        id="video_background"
        poster={backgroundPoster}
        autoPlay
        loop
        muted
        playsInline>
        <source 
          src={background} 
          type="video/mp4"
          />
      </video>
      <Container id='splashpage_container' className='d-flex'>
        <Row xs={12} className='justify-content-center align-content-center'>
          <Col className="text-center text-md-right">
            <h1 id='title'>SMARTCONTRART</h1>
          </Col>  
          <Col className="text-center text-md-right">
            <p id='blurb'>Bootstrapping NFT ventures</p>
          </Col>
        </Row>
      </Container>
      <div id='footer'>
        <a href="mailto:contact@smartcontrart.com" id='contact_us' target='_blank' rel="noreferrer">
          Contact us
        </a>
      </div>
    </div>
  );
}

export default App;
